<template>
  <div>
    <b-row align-v="stretch">
      <b-col sm="2">
        <b-card bg-variant="light" :header="$t('header.selecionar-caracteristicas')">
          <b-form-select v-model="caracteristica_selecionada" :options="optionsCaracteristicas" :select-size="29">
          </b-form-select>
        </b-card>
      </b-col>
      <b-col sm="8">
        <b-card>
          <div id="wrapper">
            <div id="chart-line2">
              <apexchart type="line" height="500" :options="chartOptions" :series="series"></apexchart>
            </div>
            <!--  <div id="chart-line">
            <apexchart type="line" height="100" :options="chartOptionsLine" :series="seriesLine"></apexchart>
          </div> -->
          </div>
        </b-card>
      </b-col>
      <b-col sm="2" class="bold">
        <b-card>
          <b-row align-h="center">
            {{ trend.caracteristicas[caracteristica_selecionada].ponto }}
            {{ trend.caracteristicas[caracteristica_selecionada].ax.toUpperCase() }}
          </b-row>
          <b-row align-h="center">
            Especificado: {{ trend.caracteristicas[caracteristica_selecionada].specifieds[0] | duasCasas }}
          </b-row>
          <b-row align-h="center">
            {{ trend.caracteristicas[caracteristica_selecionada].desvios.length }} Controle(s)
          </b-row>
        </b-card>

        <b-card class="mt-3">
          <b-row>
            <b-col>CP: {{ trend.caracteristicas[caracteristica_selecionada].cp | duasCasas }}</b-col>
            <b-col>CPK: {{ trend.caracteristicas[caracteristica_selecionada].cpk | duasCasas }}</b-col>
          </b-row>
          <b-row>
            <b-col>Media: {{ trend.caracteristicas[caracteristica_selecionada].med[0] | duasCasas }}</b-col>
            <b-col>Range: {{ trend.caracteristicas[caracteristica_selecionada].range[0] | duasCasas }}</b-col>
          </b-row>
          <b-row>
            <b-col> <span class="red">LIE:</span> {{ trend.caracteristicas[caracteristica_selecionada].lie[0] |
            duasCasas }}</b-col>
            <b-col> <span class="red">LSE:</span> {{ trend.caracteristicas[caracteristica_selecionada].lse[0] |
            duasCasas }}</b-col>
          </b-row>
          <b-row>
            <b-col> <span class="blue">LIC:</span> {{ trend.caracteristicas[caracteristica_selecionada].lic[0] |
            duasCasas }}</b-col>
            <b-col> <span class="blue">LSC:</span> {{ trend.caracteristicas[caracteristica_selecionada].lsc[0] |
            duasCasas }}</b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    trend: null,
  },
  data() {
    return {
      caracteristica_selecionada: 0,
      optionsCaracteristicas: [],
      series: [
        {
          name: "Control points",
          data: []
        },
        {
          name: "Average",
          data: []
        },
        {
          name: "Specified",
          data: []
        },
        {
          name: "LIE",
          data: []
        },
        {
          name: "LSE",
          data: []
        },
        {
          name: "LIC",
          data: []
        },
        {
          name: "LSC",
          data: []
        },
      ],
      chartOptions: {
        chart: {
          parentHeightOffset: 20,
          id: 'chart2',
          type: 'line',
          height: 230,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },
        stroke: {
          width: [3, 1, 2, 2, 2, 2, 2],
          curve: 'smooth',
          dashArray: [0, 0, 0, 0, 0, 5, 5]
        },
        colors: ['#000000', '#404040', '#009933', '#cc0000', '#cc0000', '#0066cc', '#0066cc'],
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: [6, 0, 0, 0, 0, 0, 0]
        },
        legend: {
          position: "top",
          offsetX: 0,
          offsetY: 10,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          offsetX: -10,
          decimalsInFloat: 2,
        }
      },
      seriesLine: [
        {
          name: "Control points",
          data: []
        },
      ],
      chartOptionsLine: {
        chart: {
          id: 'chart1',
          height: 130,
          type: 'line',
          brush: {
            target: 'chart2',
            enabled: true
          },
          selection: {
            enabled: true,
          },
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        colors: ['#000000'],
        fill: {
          opacity: 1,
        },
        markers: {
          size: 6
        },
        xaxis: {

          labels: {
            format: 'dd MMM yyyy',
          },
          categories: [],
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          decimalsInFloat: 2,
          tickAmount: 2
        }
      }
    };
  },
  mounted() {
    this.updateGrafico();
    this.carregarCaracteristicas();
  },
  methods: {
    carregarCaracteristicas() {
      this.menssagem = null;
      this.optionsCaracteristicas = [];
      this.trend.caracteristicas.forEach((caract, index) => {
        this.optionsCaracteristicas.push({
          value: index,
          text: caract.ponto + " - " + caract.ax.toUpperCase(),
        });
      });
    },
    updateGrafico() {
      this.series[0].data = this.trend.caracteristicas[this.caracteristica_selecionada].desvios;
      this.series[1].data = this.trend.caracteristicas[this.caracteristica_selecionada].med;
      this.series[2].data = this.trend.caracteristicas[this.caracteristica_selecionada].specifieds;
      this.series[3].data = this.trend.caracteristicas[this.caracteristica_selecionada].lie;
      this.series[4].data = this.trend.caracteristicas[this.caracteristica_selecionada].lse;
      this.series[5].data = this.trend.caracteristicas[this.caracteristica_selecionada].lic;
      this.series[6].data = this.trend.caracteristicas[this.caracteristica_selecionada].lsc;
      this.chartOptions = {
        xaxis: {
          labels: {
            formatter: function (timestamp) {
              const d = new Date(timestamp).toLocaleDateString("pt-br");
              const h = new Date(timestamp).toLocaleTimeString("pt-br");
              return d + " " + h;
            },
          },
          categories: this.trend.datas,
        },
      };
      this.seriesLine[0].data = this.trend.caracteristicas[this.caracteristica_selecionada].desvios;
      this.chartOptionsLine = {
        xaxis: {
          labels: {
            formatter: function (timestamp) {
              const d = new Date(timestamp).toLocaleDateString("pt-br");
              const h = new Date(timestamp).toLocaleTimeString("pt-br");
              return d + " " + h;
            },
          },
          categories: this.trend.datas,
        },
      };
    },
  },
  watch: {
    trend: function () {
      this.carregarCaracteristicas();
    },
    caracteristica_selecionada: function () {
      this.updateGrafico();
    },
  },
};
</script>
<style scoped>
.red {
  color: red;
}

.blue {
  color: blue;
}

.bold {
  font-weight: bold;
}
</style>
