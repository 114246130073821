<template>
  <div class="componente mt-3">
    <b-breadcrumb>
      <b-breadcrumb-item :to="'/familias'">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        {{ $t("breadcrumb.familias") }}
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="'/familia/' + componente.familia.id">{{
        componente.familia.descricao
      }}</b-breadcrumb-item>
      <b-breadcrumb-item active>{{
        $t("breadcrumb.componente")
      }}</b-breadcrumb-item>
    </b-breadcrumb>

    <SelecaoPeriodo :mostrarTurno="true" :mostrarVisualizacao="false" />

    <b-card no-body class="px-5 py-3 mt-3">
      <b-row align-v="center" align-h="between">
        <div class="card-imagem">
          <b-row align-v="center">
            <b-img center height="150" :src="componente.imagem | urlImagem" />
            <b-col>
              <h5>{{ componente.descricao }}</h5>
              <h5>
                <small class="subtitle"> {{ componente.desenho }}</small>
              </h5>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col cols="auto">
            <label>{{ $t("label.relatorio") }}</label>
            <b-form-select
              v-model="relatorio"
              :options="optionsRelatorio"
            ></b-form-select>
          </b-col>
        </b-row>

        <div>
          <b-button-toolbar
            aria-label="Toolbar with button groups and dropdown menu"
          >
            <b-button-group class="mx-1">
              <b-button
                @click="showModalFiltrarElemento()"
                :disabled="!relatorio"
              >
                <b-icon icon="search"></b-icon>
              </b-button>
            </b-button-group>

            <b-button-group
              class="mx-1"
              v-if="
                user.permissao == 'acesso_total' ||
                user.permissao == 'relatorio'
              "
            >
              <b-button
                @click="showModalCarregarControle()"
                :disabled="!relatorio"
                >{{ $t("botoes.controles") }}
              </b-button>
            </b-button-group>

            <b-dropdown class="mx-1" right :text="$t('botoes.relatorio')">
              <b-dropdown-item
                v-if="
                  user.permissao == 'acesso_total' ||
                  user.permissao == 'relatorio'
                "
                @click="showModalRelatorio()"
                >{{ $t("botoes.novo") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  user.permissao == 'acesso_total' ||
                  user.permissao == 'relatorio'
                "
                :to="'../../relatorio/' + relatorio"
                :disabled="!relatorio"
                >{{ $t("botoes.editar") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="user.permissao == 'acesso_total'"
                @click="
                  showMsgConfirmation(
                    $t(
                      'confirmacao.tem-certeza-que-deseja-remover-esse-relatorio-com-todos-seus-relatorios'
                    )
                  )
                "
                :disabled="!relatorio"
                >{{ $t("botoes.deletar") }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="showModalApresentacao()"
                :disabled="!relatorio"
              >
                {{ $t("botoes.gerarApresentacao") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  user.permissao == 'acesso_total' ||
                  user.permissao == 'relatorio'
                "
                @click="exportar()"
                :disabled="!relatorio"
              >
                {{ $t("botoes.exportar") }}
              </b-dropdown-item>
            </b-dropdown>
          </b-button-toolbar>
        </div>
      </b-row>
    </b-card>

    <div class="mt-3 mb-3" v-if="relatorio">
      <b-alert show variant="danger" v-if="menssagem" class="mt-3">
        <span>
          <b-icon icon="exclamation-octagon" aria-hidden="true"></b-icon>
          {{ menssagem }}
        </span>
      </b-alert>

      <b-card no-body v-if="controles.length > 0">
        <b-overlay :show="capturing" opacity="1" rounded="sm">
          <b-tabs card v-model="tabIndex">
            <b-tab :title="$t('tabs.tabela')" active @click="atualizar">
              <template #title v-if="carregandoTabela">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoTabela"
                ></b-spinner>
                {{ $t("tabs.tabela") }}
              </template>

              <Tabela
                :carregando="carregandoTabela"
                :tabela="resultado.tabela"
                :descricao="componente.descricao"
                :desenho="componente.desenho"
                :indicador="componente.indicador"
                :fechamento="resultado.tabela.fechamento"
                :analysis="fechamentoAnalysis"
                v-if="resultado.tabela.caracteristicas"
              />
              <b-skeleton-img v-else></b-skeleton-img>
            </b-tab>

            <b-tab :title="$t('tabs.capa')" @click="atualizar">
              <template #title v-if="carregandoCapa">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoCapa"
                ></b-spinner>
                {{ $t("tabs.capa") }}
              </template>
              <capa
                ref="capa"
                v-if="resultado.capa"
                :semana="semana"
                :descricao="componente.descricao"
                :desenho="componente.desenho"
                :imagem="componente.imagem"
                :capa="resultado.capa"
              />
            </b-tab>

            <b-tab title="WorkFlow" @click="atualizar">
              <template #title v-if="carregandoWorkflow">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoWorkflow"
                ></b-spinner>
                WorkFlow
              </template>

              <b-button
                :disabled="!relatorio"
                @click="showModalSelecionarComponentes()"
                class="mb-3"
                size="sm"
                v-if="user.permissao == 'acesso_total'"
              >
                {{ $t("botoes.selecionar-componentes") }}
              </b-button>
              <b-button
                :disabled="!relatorio"
                @click="salvarDatas()"
                class="mb-3 ml-3"
                size="sm"
                v-if="user.permissao == 'acesso_total'"
              >
                {{ $t("botoes.salvar-datas") }}
              </b-button>
              <work-flow
                :resultado="resultado"
                :componente="componente"
                v-if="resultado.tabela.fechamento"
                ref="workflow"
                @listaMudou="carregarRelatorio()"
              />
            </b-tab>

            <b-tab :title="$t('tabs.conformidade')" @click="atualizar">
              <template #title v-if="carregandoGraficoBarra">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoGraficoBarra"
                ></b-spinner>
                {{ $t("tabs.conformidade") }}
              </template>

              <b-button
                @click="showModalConfigurarRelatorio('conformidade')"
                :disabled="!relatorio"
                v-if="user.permissao == 'acesso_total'"
              >
                {{ $t("botoes.configuracoes") }}
              </b-button>
              <div class="pagina-padrao">
                <Conformidade
                  ref="relatorioConformidade"
                  :semanas="graficoBarra.cg_semanas"
                  :verde="graficoBarra.f_geral_cg_verde"
                  :amarelo="graficoBarra.f_geral_cg_amarelo"
                  :vermelho="graficoBarra.f_geral_cg_vermelho"
                  :forecast="graficoBarra.f_geral_cg_forecast"
                  calculo="CG"
                  height="600"
                  v-if="graficoBarra"
                />
              </div>
            </b-tab>

            <b-tab
              :title="componente.indicador == 'cp' ? 'Cp / Cpk' : 'Pp / Ppk'"
              @click="atualizar"
            >
              <template #title v-if="carregandoGraficoBarra">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoGraficoBarra"
                ></b-spinner>
                {{ componente.indicador == "cp" ? "Cp / Cpk" : "Pp / Ppk" }}
              </template>

              <b-button
                @click="showModalConfigurarRelatorio('cpk')"
                :disabled="!relatorio"
                v-if="user.permissao == 'acesso_total'"
              >
                {{ $t("botoes.configuracoes") }}
              </b-button>
              <div class="pagina-padrao">
                <div ref="relatorioCPCPK">
                  <Conformidade
                    ref="relatorioCPBarra"
                    :semanas="graficoBarra.cp_semanas"
                    :verde="graficoBarra.f_geral_cp_verde"
                    :amarelo="graficoBarra.f_geral_cp_amarelo"
                    :vermelho="graficoBarra.f_geral_cp_vermelho"
                    :calculo="componente.indicador == 'cp' ? 'CP' : 'PP'"
                    :forecast="graficoBarra.f_geral_cp_forecast"
                    height="300"
                    v-if="graficoBarra"
                  />
                  <Conformidade
                    ref="relatorioCPKBarra"
                    :semanas="graficoBarra.cpk_semanas"
                    :verde="graficoBarra.f_geral_cpk_verde"
                    :amarelo="graficoBarra.f_geral_cpk_amarelo"
                    :vermelho="graficoBarra.f_geral_cpk_vermelho"
                    :calculo="componente.indicador == 'cp' ? 'CPK' : 'PPK'"
                    :forecast="graficoBarra.f_geral_cpk_forecast"
                    height="300"
                    v-if="graficoBarra"
                  />
                </div>
              </div>
            </b-tab>

            <b-tab :title="$t('tabs.boleado')" @click="atualizar">
              <template #title v-if="carregandoBoleado">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoBoleado"
                ></b-spinner>
                {{ $t("tabs.boleado") }}
              </template>

              <b-button
                :disabled="!relatorio"
                @click="showModalCriterioBoleado()"
                class="mb-3"
                v-if="user.permissao == 'acesso_total'"
              >
                {{ $t("botoes.criterio") }}
              </b-button>
              <b-button class="mb-3 ml-2" v-if="printGenerateProgress" disabled>
                <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                {{ $t("botoes.carregando") }}
              </b-button>
              <b-button
                v-else
                @click="generateReportBoleado()"
                class="mb-3 ml-2"
              >
                <b-icon icon="printer-fill"
              /></b-button>

              <relatorio-boleado
                ref="relatorioBoleado"
                v-if="resultado_boleado"
                :relatorio_id="relatorio"
                :paginas="resultado.paginas"
                :resultado="resultado_boleado"
                :indicador="componente.indicador"
                :componente="componente"
              />

              <impressao-relatorio-boleado
                ref="impressaoRelatorioBoleado"
                v-if="resultado_boleado"
                :relatorio_id="relatorio"
                :paginas="resultado.paginas"
                :resultado="resultado_boleado"
                :indicador="componente.indicador"
                @alterStatusPdfGenerate="alterStatusPdfGenerate($event)"
                :componente="componente"
              />
            </b-tab>

            <b-tab
              :title="componente.indicador == 'cp' ? 'Cpk' : 'Ppk'"
              @click="atualizar"
            >
              <template #title v-if="carregandoCPK">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoTabela"
                ></b-spinner>
                {{ componente.indicador == "cp" ? "Cpk" : "Ppk" }}
              </template>

              <b-button class="mb-3 ml-2" v-if="printGenerateProgress" disabled>
                <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                {{ $t("botoes.carregando") }}
              </b-button>
              <b-button v-else @click="generateReportCpk()" class="mb-3 ml-2">
                <b-icon icon="printer-fill"
              /></b-button>
              <relatorio-cpk
                ref="relatorioCpk"
                v-if="relatorio"
                :relatorio_id="relatorio"
                :paginas="resultado.paginas"
                :resultado="resultado"
                :indicador="componente.indicador"
                :componente="componente"
              />
              <impressao-relatorio-cpk
                ref="impressaoRelatorioCpk"
                v-if="resultado"
                :relatorio_id="relatorio"
                :paginas="resultado.paginas"
                :resultado="resultado"
                :indicador="componente.indicador"
                :componente="componente"
                @alterStatusPdfGenerate="alterStatusPdfGenerate($event)"
              />
            </b-tab>

            <b-tab :title="$t('tabs.individual')" @click="atualizar">
              <template #title v-if="carregandoRelatorioIndividual">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoRelatorioIndividual"
                ></b-spinner>
                {{ $t("tabs.individual") }}
              </template>

              <b-button
                :disabled="!relatorio"
                @click="showModalControleIndividual()"
                class="mb-3"
              >
                {{ $t("botoes.selecionar-controle") }}
              </b-button>
              <b-button class="mb-3 ml-2" v-if="printGenerateProgress" disabled>
                <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                {{ $t("botoes.carregando") }}
              </b-button>
              <b-button v-else @click="generateReport()" class="mb-3 ml-2">
                <b-icon icon="printer-fill"
              /></b-button>

              <relatorio-individual
                ref="relatorioIndividual"
                v-if="resultadoIndividual"
                :relatorio_id="relatorio"
                :resultado="resultadoIndividual"
                :componente="componente"
                :indicador="componente.indicador"
              />
              <impressao-relatorio-individual
                ref="impressaoRelatorioIndividual"
                v-if="resultadoIndividual"
                :relatorio_id="relatorio"
                :resultado="resultadoIndividual"
                :componente="componente"
                :indicador="componente.indicador"
                @alterStatusPdfGenerate="alterStatusPdfGenerate($event)"
              />
            </b-tab>
            <b-tab :title="$t('tabs.plano-de-acao')" @click="atualizar">
              <template #title v-if="carregandoPlanoAcao">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoPlanoAcao"
                ></b-spinner>
                {{ $t("tabs.plano-de-acao") }}
              </template>
              <b-button
                :disabled="!relatorio"
                @click="showModalPlanoDeAcao()"
                class="mb-3"
                v-if="user.permissao != 'visualizar'"
              >
                {{ $t("botoes.novo-plano-de-acao") }}
              </b-button>

              <b-button
                :disabled="!relatorio"
                @click="enviarPlanoDeAcao()"
                class="mb-3 ml-2"
                v-if="user.permissao == 'acesso_total'"
              >
                {{ $t("botoes.enviar-para-os-responsaveis") }}
              </b-button>
              <div class="pagina-padrao">
                <Plano
                  :tabela="resultado.tabela"
                  :plano="resultado.plano.plano"
                  :descricao="componente.descricao"
                  :desenho="componente.desenho"
                  :semanas="resultado.plano.semanas"
                  :semana="semana"
                  :indicador="componente.indicador"
                  :capturing="capturing"
                  v-if="resultado.plano"
                  @editarPlano="editarPlano"
                  ref="planoDeAcao"
                />
              </div>
            </b-tab>
            <b-tab :title="$t('tabs.risk')">
              <template #title v-if="carregandoPlanoAcao">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoPlanoAcao"
                ></b-spinner>
                {{ $t("tabs.risk") }}
              </template>
              <div class="pagina-padrao">
                <risk-assesment
                  ref="risk"
                  v-if="resultado.plano"
                  :analysis="resultado.plano.fechamento_analysis"
                  :analysis_type="resultado.plano.fechamento_analysis_type"
                  :risks="resultado.tabela.risks"
                  :componente="componente"
                />
              </div>
            </b-tab>
            <b-tab :title="$t('tabs.grafico-tendencia')">
              <template #title v-if="carregandoTrend">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoTrend"
                ></b-spinner>
                {{ $t("tabs.grafico-tendencia") }}
              </template>
              <grafico-trend :trend="resultado.trend" v-if="resultado.trend" />
            </b-tab>
            <b-tab :title="$t('tabs.gaus')">
              <template #title v-if="carregandoTrend">
                <b-spinner
                  type="border"
                  small
                  v-if="carregandoTrend"
                ></b-spinner>
                {{ $t("tabs.gaus") }}
              </template>
              <gauss :trend="resultado.trend" v-if="resultado.trend" />
            </b-tab>
          </b-tabs>
        </b-overlay>
      </b-card>
    </div>
    <modal-componente
      :familia="componente.familia"
      :componente="componente"
      @listaMudou="carregarComponente()"
    />
    <modal-relatorio
      :componente="componente"
      @listaMudou="carregarComponente()"
    />

    <modal-carregar-controle
      v-if="relatorio && fechamento == 1"
      :controles="controles"
      :relatorio="relatorio"
      :conversor_relatorio="conversor_relatorio"
      @controlesMudou="carregarRelatorio()"
    />
    <modal-carregar-controle-manual
      v-if="relatorio && fechamento == 2"
      :relatorio="relatorio"
      :conversor_relatorio="conversor_relatorio"
      @controlesMudou="carregarRelatorio()"
    />

    <modal-controle-individual
      v-if="relatorio"
      :relatorio="relatorio"
      :controles="controles"
      @controle_id="setarControleIndividual"
    />
    <modal-configurar-relatorio
      v-if="relatorio"
      :relatorio="relatorio"
      :grafico="grafico"
      @configMudou="carregarRelatorio()"
    />
    <modal-plano-de-acao
      v-if="resultado.tabela.caracteristicas"
      :caracteristicas="resultado.tabela.caracteristicas"
      :relatorio_id="relatorio"
      :plano="plano_selecionado"
      :indicador="componente.indicador"
      :planos="crt_planos()"
      @listaMudou="carregarRelatorio()"
    />
    <modal-work-flow
      v-if="resultado.workflow"
      :workflow="resultado.workflow"
      :relatorio_id="relatorio"
      @listaMudou="carregarRelatorio()"
    />
    <modal-criterio-boleado
      v-if="relatorio_selecionado"
      :relatorio="relatorio_selecionado[0]"
      @listaMudou="carregarRelatorio()"
      :indicador="resultado.componente.indicador"
    />
    <modal-carregando :count="count" :tabs="quantidade_paginas_imprimir" />
    <modal-apresentacao @gerarApresentacao="gerarApresentacao" />
    <modal-carregando-geral />
    <modal-filtrar-elemento
      v-if="relatorio"
      :relatorio="relatorio"
      @elemento_id="setarElementoFiltrado"
    />
    <!-- <modal-trend v-if="resultado.tabela.caracteristicas" :caracteristicas="resultado.tabela.caracteristicas"
      :relatorio_id="relatorio"/> -->
  </div>
</template>

<style scoped>
table.comBordaComplexa {
  text-align: center;
  font-weight: bold;
  border-collapse: collapse;
  /* CSS2 */
  background: #fffff0;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}

.subtitle {
  font-weight: 500;
  color: gray;
}

.overlay {
  position: absolute !important;
  width: 100%;
  height: 100%;
  text-align: center;
}

.modal-backdrop {
  opacity: 1 !important;
  background-color: #e4e4e4 !important;
}

.pagina-padrao {
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card-imagem {
  height: 150px;
}
</style>

<script>
import Componente from "@/components/Componentes.vue";
import Conformidade from "@/components/Conformidade.vue";
import GraficoTrend from "@/components/GraficoTrend.vue";
import RiskAssesment from "@/components/RiskAssesment.vue";
import Gauss from "@/components/Gauss.vue";
import SelecaoPeriodo from "@/components/SelecaoPeriodo.vue";

//Modal
import ModalComponente from "@/components/modal/Componente.vue";
import ModalRelatorio from "@/components/modal/Relatorio.vue";
import ModalPlanoDeAcao from "@/components/modal/PlanoDeAcao.vue";
import ModalCarregarControle from "@/components/modal/CarregarControle.vue";
import ModalCarregarControleManual from "@/components/modal/CarregarControleManual.vue";
import ModalControleIndividual from "@/components/modal/ControleIndividual.vue";
import ModalConfigurarRelatorio from "@/components/modal/ConfiguracaoRelatorio.vue";
import ModalCriterioBoleado from "@/components/modal/CriterioBoleado.vue";
import ModalWorkFlow from "@/components/modal/WorkFlow.vue";
import ModalCarregando from "@/components/modal/Carregando.vue";
import ModalApresentacao from "@/components/modal/Apresentacao.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import ModalFiltrarElemento from "@/components/modal/FiltrarElemento.vue";

//relatorio
import RelatorioCpk from "@/views/relatorio/RelatorioCpk.vue";
import RelatorioIndividual from "@/views/relatorio/RelatorioIndividual.vue";
import RelatorioBoleado from "@/views/relatorio/RelatorioBoleado.vue";
import Tabela from "@/views/relatorio/Tabela.vue";
import Plano from "@/views/relatorio/Plano.vue";
import WorkFlow from "@/views/relatorio/WorkFlow.vue";
import Capa from "@/views/relatorio/Capa.vue";

//gerar imagens

import * as htmlToImage from "html-to-image";
import ImpressaoRelatorioIndividual from "@/views/relatorio/ImpressaoRelatorioIndividual.vue";
import ImpressaoRelatorioCpk from "@/views/relatorio/ImpressaoRelatorioCpk.vue";
import ImpressaoRelatorioBoleado from "@/views/relatorio/ImpressaoRelatorioBoleado.vue";

export default {
  name: "Componente",
  computed: {
    filter() {
      var filter =
        "?tipoPeriodo=" +
        this.$store.state.tipoPeriodo +
        "&ano=" +
        this.$store.state.ano +
        "&mes=" +
        this.$store.state.mes +
        "&semana=" +
        this.$store.state.semana +
        "&data=" +
        this.$store.state.data +
        "&turno=" +
        this.$store.state.turno;

      if (this.elemento_id_filtrado) {
        filter = filter + "&elemento_id=[" + this.elemento_id_filtrado + "]";
      }

      return filter;
    },
    user() {
      return this.$store.state.auth.user.user;
    },
    conversor_relatorio() {
      if (!this.relatorio) {
        return null;
      }
      return this.componente.relatorios.filter(
        (relat) => relat.id == this.relatorio
      )[0].conversor;
    },
    fechamento: {
      get() {
        return this.$store.state.fechamento;
      },
    },
    turno: {
      get() {
        return this.$store.state.turno;
      },
      set(value) {
        this.$store.commit("setTurno", value);
      },
    },
    tipoPeriodo: {
      get() {
        return this.$store.state.tipoPeriodo;
      },
      set(value) {
        this.$store.commit("setTipoPeriodo", value);
      },
    },
    mes: {
      get() {
        return this.$store.state.mes;
      },
      set(value) {
        this.$store.commit("setMes", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    visualizacao: {
      get() {
        return this.$store.state.visualizacao;
      },
      set(value) {
        this.$store.commit("setVisualizacao", value);
      },
    },
    data: {
      get() {
        return this.$store.state.data;
      },
      set(value) {
        this.$store.commit("setData", value);
      },
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
  },
  components: {
    Componente,
    Conformidade,
    GraficoTrend,
    RiskAssesment,
    Gauss,
    SelecaoPeriodo,

    RelatorioCpk,
    RelatorioIndividual,
    Tabela,
    Plano,
    RelatorioBoleado,
    WorkFlow,
    Capa,

    ModalComponente,
    ModalRelatorio,
    ModalCarregarControle,
    ModalApresentacao,
    ModalConfigurarRelatorio,
    ModalPlanoDeAcao,
    ModalControleIndividual,
    ModalCriterioBoleado,
    ModalWorkFlow,
    ModalCarregando,
    ModalCarregandoGeral,
    ModalCarregarControleManual,
    ImpressaoRelatorioIndividual,
    ImpressaoRelatorioCpk,
    ImpressaoRelatorioBoleado,
    ModalFiltrarElemento,
  },
  data() {
    return {
      carregandoTabela: false,
      carregandoCapa: false,
      carregandoWorkflow: false,
      carregandoGraficoBarra: false,
      carregandoCPK: false,
      carregandoRelatorioIndividual: false,
      carregandoPlanoAcao: false,
      carregandoTrend: false,
      carregandoBoleado: false,

      resultadoIndividual: null,
      graficoBarra: null,
      controles: [],
      fechamentoAnalysis: null,
      url: null,
      img: null,
      tabIndex: 1,
      capturing: false,
      grafico: "conformidade",
      componente: {
        id: null,
        desenho: null,
        descricao: null,
        imagem: null,
        familia: {
          descricao: null,
        },
        relatorios: [],
      },
      relatorio_selecionado: null,
      relatorio: null,
      controle_individual_id: null,
      elemento_id_filtrado: null,
      optionsRelatorio: [],
      carregando: false,
      menssagem: null,
      resultado_boleado: null,
      resultado: {
        componente: {
          descricao: null,
          desenho: null,
        },
        paginas: [],
        semanas: [],
        tabela: {
          caracteristicas: null,
        },
      },
      limpar: {
        componente: {
          descricao: null,
          desenho: null,
        },
        paginas: [],
        semanas: [],
        tabela: {
          caracteristicas: null,
          risks: {
            quantidade: null,
            cor: null,
          },
        },
      },
      plano_selecionado: {
        id: null,
      },
      tabs: [
        { tab: 1, ref: "capa" },
        { tab: 2, ref: "workflow" },
        { tab: 3, ref: "relatorioConformidade" },
        { tab: 4, ref: "relatorioCPCPK" },
        { tab: 5, ref: "relatorioBoleado" },
        { tab: 6, ref: "relatorioCpk" },
        { tab: 7, ref: "relatorioIndividual" },
        { tab: 8, ref: "planoDeAcao" },
        { tab: 9, ref: "risk" },
      ],
      screenshots: {
        capa: [],
        workflow: [],
        relatorioConformidade: [],
        relatorioCPCPK: [],
        relatorioBoleado: [],
        relatorioCpk: [],
        relatorioIndividual: [],
        planoDeAcao: [],
        risk: [],
      },
      imprimir: null,
      count: 0,
      quantidade_paginas_imprimir: 0,
      imagemPlanoAcao: null,
      printGenerateProgress: false,
    };
  },
  methods: {
    alterStatusPdfGenerate($status) {
      this.printGenerateProgress = $status;
    },
    generateReport() {
      this.$refs.impressaoRelatorioIndividual.$refs.html2Pdf.generatePdf();
    },
    generateReportCpk() {
      this.$refs.impressaoRelatorioCpk.$refs.html2Pdf.generatePdf();
    },
    generateReportBoleado() {
      this.$refs.impressaoRelatorioBoleado.$refs.html2Pdf.generatePdf();
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    enviarPlanoDeAcao() {
      this.$bvModal.show("modal-carregando-geral");
      setTimeout(() => {
        const capture = this.$refs["planoDeAcao"].$el;
        htmlToImage
          .toPng(capture)
          .then((dataUrl) => {
            var img = new Image();
            img.src = dataUrl;
            this.imagemPlanoAcao = img;
            this.enviarPlano();
          })
          .catch((error) => {
            console.error("oops, something went wrong!", error);
          });
      }, 300);
    },
    enviarPlano() {
      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio);
      formData.append("imagem", this.imagemPlanoAcao.src);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/plano/enviar-para-responsaveis", formData, config)
        .then((response) => {
          this.makeToast("success");
          this.capturing = false;
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          if (errors.response) {
            this.menssagem = errors.response.data;
          }
          this.carregando = false;
          this.capturing = false;

          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    enviarDadosApresentacao() {
      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio);
      formData.append("semana", this.semana);
      formData.append("ano", this.ano);
      formData.append("tipo", "relatorio");

      if (this.imprimir.print_image && this.imprimir.print_anexo.length > 0) {
        this.imprimir.print_anexo.forEach((element) => {
          formData.append("print_anexo[]", element);
        });
      }

      if (this.screenshots.capa) {
        this.screenshots.capa.forEach((element) => {
          formData.append("capa[]", element.src);
        });
      }
      if (this.screenshots.workflow) {
        this.screenshots.workflow.forEach((element) => {
          formData.append("workflow[]", element.src);
        });
      }
      if (this.screenshots.relatorioConformidade) {
        this.screenshots.relatorioConformidade.forEach((element) => {
          formData.append("relatorioConformidade[]", element.src);
        });
      }
      if (this.screenshots.relatorioCPCPK) {
        this.screenshots.relatorioCPCPK.forEach((element) => {
          formData.append("relatorioCPCPK[]", element.src);
        });
      }
      if (this.screenshots.relatorioBoleado) {
        this.screenshots.relatorioBoleado.forEach((element) => {
          formData.append("relatorioBoleado[]", element.src);
        });
      }
      if (this.screenshots.relatorioCpk) {
        this.screenshots.relatorioCpk.forEach((element) => {
          formData.append("relatorioCpk[]", element.src);
        });
      }
      if (this.screenshots.relatorioIndividual) {
        this.screenshots.relatorioIndividual.forEach((element) => {
          formData.append("relatorioIndividual[]", element.src);
        });
      }
      if (this.screenshots.planoDeAcao) {
        this.screenshots.planoDeAcao.forEach((element) => {
          formData.append("planoDeAcao[]", element.src);
        });
      }
      if (this.screenshots.risk) {
        this.screenshots.risk.forEach((element) => {
          formData.append("risk[]", element.src);
        });
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        maxContentLength: 1000000000000,
        maxBodyLength: 10000000000000,
      };

      this.$http
        .post("api/apresentacao", formData, config)
        .then((response) => {
          var path = this.url + "/" + response.data.arquivo;
          var nome = response.data.arquivo;

          this.download(path, nome);

          setTimeout(() => {
            this.makeToast("success");
            this.capturing = false;
            this.count = 0;
            document.getElementById("app").style.backgroundColor = "";
            document.getElementById("app").style.opacity = "";

            var els = document.getElementsByClassName("tab-pane");

            Array.prototype.forEach.call(els, function (el, index) {
              if (index == 0) {
                el.style.display = "block";
              } else {
                el.style.display = "none";
              }
            });

            this.tabIndex = 0;

            this.imprimir = null;

            this.$bvModal.hide("modal-carregando");
          }, 300);
        })
        .catch((errors) => {
          if (errors.response) {
            this.menssagem = errors.response.data;
          }

          this.carregando = false;
          this.capturing = false;
          this.count = 0;
          document.getElementById("app").style.backgroundColor = "";
          document.getElementById("app").style.opacity = "";

          var els = document.getElementsByClassName("tab-pane");

          Array.prototype.forEach.call(els, function (el, index) {
            if (index == 0) {
              el.style.display = "block";
            } else {
              el.style.display = "none";
            }
          });

          this.tabIndex = 0;
          this.$bvModal.hide("modal-carregando");
        });
    },
    download(file, nome) {
      window.location.href = file;
    },
    gerarApresentacao(imp) {
      this.imprimir = imp;
      this.onCapture();
    },
    capiturando() {
      if (this.count == this.quantidade_paginas_imprimir) {
        setTimeout(() => {
          this.enviarDadosApresentacao();
        }, 300);
      }
    },
    onCapture() {
      this.capturing = true;

      document.getElementById("app").style.backgroundColor = "#e4e4e4";
      document.getElementById("app").style.opacity = 0;

      var els = document.getElementsByClassName("tab-pane");

      Array.prototype.forEach.call(els, function (el) {
        el.style.display = "block";
      });

      this.quantidade_paginas_imprimir = 0;
      this.count = 0;

      this.tabs.forEach((tab, i) => {
        if (tab.tab == 1) {
          if (this.imprimir.print_capa == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 2) {
          if (this.imprimir.print_workflow == true) {
            this.quantidade_paginas_imprimir +=
              1 + this.$refs[tab.ref].paginas.length;
          }
        }

        if (tab.tab == 3) {
          if (this.imprimir.print_conformidade == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 4) {
          if (this.imprimir.print_cpk == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 5) {
          if (this.imprimir.print_boleado == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 6) {
          if (this.imprimir.print_relatorio == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 7) {
          if (this.imprimir.print_individual == true) {
            this.quantidade_paginas_imprimir +=
              this.$refs[tab.ref].resultado.paginas.length;
          }
        }

        if (tab.tab == 8) {
          if (this.imprimir.print_plano == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 9) {
          if (this.imprimir.print_risk == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }
      });

      this.showModalCarregando();
      this.atualizar();
      this.screenshots = {
        capa: [],
        workflow: [],
        relatorioConformidade: [],
        relatorioCPCPK: [],
        relatorioBoleado: [],
        relatorioCpk: [],
        relatorioIndividual: [],
        planoDeAcao: [],
        risk: [],
      };

      setTimeout(() => {
        this.tabs.forEach((tab, i) => {
          //this.tabIndex = tab.tab;

          if (tab.tab == 1) {
            if (this.imprimir.print_capa == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.capa.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 2) {
            if (this.imprimir.print_workflow == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$refs.pagina_workflow0;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.workflow.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });

                this.$refs[tab.ref].paginas.forEach((pg, o) => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_workflow" + (o + 1)][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.workflow.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                });
              }, 300);
            }
          }

          if (tab.tab == 3) {
            if (this.imprimir.print_conformidade == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.relatorioConformidade.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 4) {
            if (this.imprimir.print_cpk == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.relatorioCPCPK.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 5) {
            if (this.imprimir.print_boleado == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_boleado" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioBoleado.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }
          }

          if (tab.tab == 6) {
            if (this.imprimir.print_relatorio == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_cpk" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioCpk.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }
          }

          if (tab.tab == 7) {
            if (this.imprimir.print_individual == true) {
              this.$refs[tab.ref].resultado.paginas.forEach((pg, u) => {
                setTimeout(() => {
                  const capture =
                    this.$refs[tab.ref].$refs["pagina_individual" + u][0];

                  htmlToImage
                    .toPng(capture)
                    .then((dataUrl) => {
                      var img = new Image();
                      img.src = dataUrl;
                      this.screenshots.relatorioIndividual.push(img);
                      this.count++;
                      this.capiturando();
                    })
                    .catch((error) => {
                      console.error("oops, something went wrong!", error);
                    });
                }, 300);
              });
            }
          }

          if (tab.tab == 8) {
            if (this.imprimir.print_plano == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.planoDeAcao.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }

          if (tab.tab == 9) {
            if (this.imprimir.print_risk == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;

                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.risk.push(img);
                    this.count++;
                    this.capiturando();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 300);
            }
          }
        });
      }, 300);
    },
    crt_planos() {
      var ctr = [];

      if (this.resultado.plano) {
        this.resultado.plano.plano.forEach(function (item) {
          ctr.push(
            item.caracteristica.elemento + " - " + item.caracteristica.ax
          );
        });
      }

      return ctr;
    },
    selecionar_relatorio() {
      this.relatorio_selecionado = this.componente.relatorios.filter(
        (rl) => rl.id == this.relatorio
      );
    },
    atualizar() {
      if (this.relatorio && this.graficoBarra) {
        if (
          this.resultado.paginas.length > 0 &&
          this.resultado.plano &&
          this.resultado.tabela
        ) {
          this.carregarControleBoleado();
        }

        this.$refs.impressaoRelatorioCpk.carregarRelatorio();
        this.$refs.relatorioCpk.carregarRelatorio();
        this.$refs.relatorioIndividual.carregarRelatorio();
        this.$refs.impressaoRelatorioIndividual.carregarRelatorio();
        this.$refs.relatorioConformidade.updateGrafico();
        this.$refs.relatorioCPBarra.updateGrafico();
        this.$refs.relatorioCPKBarra.updateGrafico();
        this.$refs.impressaoRelatorioBoleado.carregarRelatorio();
        this.$refs.relatorioBoleado.carregarRelatorio();
      }
    },
    setarControleIndividual(controle_id) {
      this.controle_individual_id = controle_id;
      this.carregarRelatorioIndividual();
    },
    setarElementoFiltrado(elemento_id) {
      this.elemento_id_filtrado = elemento_id;
      this.carregarRelatorio();
    },
    editarPlano(e) {
      this.plano_selecionado = this.resultado.planos.filter(
        (p) => p.id == e
      )[0];
      this.$bvModal.show("modal-plano-de-acao");
    },
    salvarDatas() {
      this.$refs.workflow.atualizarData();
    },
    showModalSelecionarComponentes() {
      this.$bvModal.show("modal-work-flow");
    },
    showModalCriterioBoleado() {
      this.$bvModal.show("modal-criterio");
    },
    showModalControleIndividual() {
      this.$bvModal.show("modal-controle-individual");
    },
    showModalCarregarControle() {
      this.$bvModal.show("modal-controle");
    },
    showModalFiltrarElemento() {
      this.$bvModal.show("modal-filtrar-elemento");
    },
    showModalComponente() {
      this.$bvModal.show("modal-componente");
    },
    showModalRelatorio() {
      this.$bvModal.show("modal-relatorio");
    },
    showModalConfigurarRelatorio(tipoGrafico) {
      this.grafico = tipoGrafico;
      this.$bvModal.show("modal-config-relatorio");
    },
    showModalPlanoDeAcao() {
      this.plano_selecionado = { id: null };
      this.$bvModal.show("modal-plano-de-acao");
    },
    showModalCarregando() {
      this.$bvModal.show("modal-carregando");
    },
    showModalApresentacao() {
      this.$bvModal.show("modal-apresentacao");
    },
    showModalTrend() {
      this.$bvModal.show("modal-trend");
    },
    carregarComponente() {
      this.$http
        .get("api/componente/" + this.$route.params.id)
        .then((response) => {
          this.componente = response.data;
          this.optionsRelatorio = [];
          response.data.relatorios.forEach((element) => {
            this.optionsRelatorio.push({
              value: element.id,
              text: element.descricao,
            });
          });

          if (this.$route.params.relatorio) {
            this.relatorio = this.$route.params.relatorio;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregarControleBoleado() {
      this.carregandoBoleado = true;
      this.resultado_boleado = null;

      try {
        
        this.resultado_boleado = JSON.parse(JSON.stringify(this.resultado));
        this.resultado_boleado.paginas =  this.resultado_boleado.paginas.filter(item => item.elementos.length > 0);

        if (this.resultado_boleado.paginas.length == 0) {
          this.carregandoBoleado = false;
          return;
        }

        if (
          this.relatorio_selecionado[0].boleado_indicador != null &&
          this.relatorio_selecionado[0].boleado_criterio != null
        ) {
          if (
            this.relatorio_selecionado[0].boleado_indicador == "conformidade"
          ) {
            if (this.relatorio_selecionado[0].boleado_criterio == "vermelho") {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (item[eixo + "medcor"] == "red") {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            } else if (
              this.relatorio_selecionado[0].boleado_criterio == "amarelo"
            ) {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (item[eixo + "medcor"] == "yellow") {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            } else {
              this.resultado_boleado.paginas.forEach((pagina) => {
                pagina.elementos.forEach(function (item, index, object) {
                  var arrayIndex = [];
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (
                        item[eixo + "medcor"] == "yellow" ||
                        item[eixo + "medcor"] == "red"
                      ) {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            }
          } else if (this.relatorio_selecionado[0].boleado_indicador == "cpk") {
            if (this.relatorio_selecionado[0].boleado_criterio == "vermelho") {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;
                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (item[eixo + "cpkcor"] == "red") {
                        itens_filtro++;
                      }
                    }
                  });
                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            } else if (
              this.relatorio_selecionado[0].boleado_criterio == "amarelo"
            ) {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (item[eixo + "cpkcor"] == "yellow") {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            } else {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (
                        item[eixo + "cpkcor"] == "yellow" ||
                        item[eixo + "cpkcor"] == "red"
                      ) {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            }
          } else {
            if (this.relatorio_selecionado[0].boleado_criterio == "vermelho") {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (item[eixo + "cpcor"] == "red") {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            } else if (
              this.relatorio_selecionado[0].boleado_criterio == "amarelo"
            ) {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (item[eixo + "cpcor"] == "yellow") {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            } else {
              this.resultado_boleado.paginas.forEach((pagina) => {
                var arrayIndex = [];
                pagina.elementos.forEach(function (item, index, object) {                  
                  var eixos = ["x", "y", "z", "t", "d", "l", "w", "p", "a"];
                  var itens_filtro = 0;

                  eixos.forEach((eixo) => {
                    if (item["exibir" + eixo] == 1) {
                      if (
                        item[eixo + "cpcor"] == "yellow" ||
                        item[eixo + "cpcor"] == "red"
                      ) {
                        itens_filtro++;
                      }
                    }
                  });

                  if (itens_filtro == 0) {
                    arrayIndex.push(item);
                  }
                });
                arrayIndex.forEach(function (item) {
                  pagina.elementos.splice(pagina.elementos.indexOf(item), 1);
                });
              });
            }
          }
        }

        this.resultado_boleado.paginas = this.resultado_boleado.paginas.filter(
          (item) => item.elementos.length > 0
        );

      } catch (error) {
        console.log(error);
        this.carregandoBoleado = false;
      }

      this.carregandoBoleado = false;
    },
    carregarRelatorio() {
      if (this.relatorio) {
        this.carregando = true;
        this.menssagem = null;

        this.selecionar_relatorio();

        this.carregarControles();
      }
    },
    deletar() {
      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/relatorio/" + this.relatorio, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.carregando = false;
          this.carregarComponente();
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    showMsgConfirmation(msg) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: this.$t("title.confirme-por-favor"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("confirmacao.sim"),
          cancelTitle: this.$t("confirmacao.nao"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportar() {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/zip",
        },
      };
      this.$http
        .get("api/relatorio/exportar/" + this.relatorio, config)
        .then((response) => {
          var path = this.url + "/" + response.data.arquivo;
          var nome = response.data.arquivo;
          this.download(path, nome);

          this.makeToast("success");
          this.carregando = false;
          this.carregarComponente();
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    carregarTabela() {
      this.carregandoTabela = true;

      this.$http
        .get("api/resultadoRelatorioTabela/" + this.relatorio + this.filter)
        .then((response) => {
          this.resultado.tabela = response.data;
          this.carregandoTabela = false;

          if (
            this.resultado.paginas.length > 0 &&
            this.resultado.plano &&
            this.resultado.tabela
          ) {
            this.carregarControleBoleado();
          }
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoTabela = false;
        });
    },
    carregarCapa() {
      this.carregandoCapa = true;

      this.$http
        .get("api/capa?relatorioId=" + this.relatorio)
        .then((response) => {
          this.resultado.capa = response.data;
          this.carregandoCapa = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoCapa = false;
        });
    },
    carregarWorkflow() {
      this.carregandoWorkflow = true;

      this.$http
        .get("api/resultadoRelatorioWorkflow/" + this.relatorio + this.filter)
        .then((response) => {
          this.resultado.workflow = response.data;
          this.carregandoWorkflow = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoWorkflow = false;
        });
    },
    carregarGraficoBarra() {
      this.carregandoGraficoBarra = true;

      this.$http
        .get(
          "api/resultadoRelatorioGraficoBarra/" + this.relatorio + this.filter
        )
        .then((response) => {
          this.graficoBarra = response.data;
          this.carregandoGraficoBarra = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoGraficoBarra = false;
        });
    },
    carregarCPK() {
      this.carregandoCPK = true;

      this.$http
        .get("api/resultadoRelatorioCPK/" + this.relatorio + this.filter)
        .then((response) => {
          this.resultado.paginas = response.data;
          this.carregandoCPK = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoCPK = false;
        });
    },
    carregarRelatorioIndividual() {
      this.carregandoRelatorioIndividual = true;

      var newFilter = this.filter;
      if (this.controle_individual_id) {
        newFilter = newFilter + "&controle_id=" + this.controle_individual_id;
      }

      this.$http
        .get("api/resultadoRelatorioIndividual/" + this.relatorio + newFilter)
        .then((response) => {
          this.resultadoIndividual = response.data;
          this.$refs.relatorioIndividual.carregarRelatorio();
          this.$refs.impressaoRelatorioIndividual.carregarRelatorio();
          this.carregandoRelatorioIndividual = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoRelatorioIndividual = false;
        });
    },
    carregarPlanoAcao() {
      this.carregandoPlanoAcao = true;

      this.$http
        .get("api/resultadoPlanoAcao/" + this.relatorio + this.filter)
        .then((response) => {
          this.resultado.plano = response.data;
          this.carregandoPlanoAcao = false;
          this.fechamentoAnalysis = this.resultado.plano.fechamento_analysis;
          this.crt_planos();

          if (
            this.resultado.paginas.length > 0 &&
            this.resultado.plano &&
            this.resultado.tabela
          ) {
            this.carregarControleBoleado();
          }
        })
        .catch((errors) => {
          this.carregandoPlanoAcao = false;
          console.log(errors);
        });
    },
    carregarTrend() {
      this.carregandoTrend = true;

      this.$http
        .get("api/resultadoTrend/" + this.relatorio + this.filter)
        .then((response) => {
          this.resultado.trend = response.data;
          this.carregandoTrend = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.carregandoTrend = false;
        });
    },
    carregarControles() {
      this.carregando = true;
      this.$bvModal.show("modal-carregando-geral");
      this.controles = [];

      this.$http
        .get("api/relatorio/controles/" + this.relatorio + this.filter)
        .then((response) => {
          this.controles = response.data;

          if (response.data.length == 0) {
            this.menssagem = "não existe controles para o período selecionado";
          } else {
            this.carregarTabela();
            this.carregarCapa();
            this.carregarWorkflow();
            this.carregarGraficoBarra();
            this.carregarCPK();
            this.carregarRelatorioIndividual();
            this.carregarPlanoAcao();
            this.carregarTrend();
          }

          this.carregando = false;
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          this.menssagem = errors.response;
          this.carregando = false;
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
  },
  mounted() {
    this.carregarComponente();

    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    relatorio: function () {
      this.resultado = this.limpar;
      this.carregarRelatorio();
    },
    tipoPeriodo: function () {
      this.carregarRelatorio();
    },
    turno: function () {
      this.carregarRelatorio();
    },
    data: function () {
      this.carregarRelatorio();
    },
    mes: function () {
      this.carregarRelatorio();
    },
    semana: function () {
      this.carregarRelatorio();
    },
    ano: function () {
      this.carregarRelatorio();
    },
    resultado: {
      handler() {
        if (
          this.resultado.paginas.length > 0 &&
          this.resultado.plano &&
          this.resultado.tabela
        ) {
          this.carregarControleBoleado();
        }
      },
      deep: true,
    },
    carregandoTabela: function () {
      if (this.carregandoTabela == false) {
        this.atualizar();
      }
    },
  },
};
</script>
